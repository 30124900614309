
@font-face {
  font-family: "Articulate";
  src: url("../fonts/Articulat\ CF\ Medium.otf");
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #003A33;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  border-radius: 20px;
}

:root {
  --background: #003A33;
  --yellow: #E8FF8B;
  --green: #003A33;
  --text: #013829;
}

h1  {
  font-size: 2em;
  margin: 0;
}

p {
  font-size: 1.2em;
  margin: 0;
}

