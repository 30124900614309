.ServicoSection {
    width: 1400px;
    height: 800px;
    background-color: #FFFFFF;
    border-radius: 15px;
    display: flex;
    position: relative;
}

.Div1 {
    background-color: #003A33;
    border-radius: 15px 0 0 15px;
    display: flex;
}

.Banner {
    border-radius: 10px 0px 0px 10px;
}



.Div2 {
    display: flex;
    flex-direction: column;
    background-color: #E4E8EE;
    border-radius: 0px 10px 10px 0px;
    padding: 2%;
   
}

.FormHeader {
    height: 50%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 40px;
    align-items: center;
    justify-content: center;
    color: #013829;
}

.FormHeader p {
    color: #013829;
}

.Formulario {
    height: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Formulario input {
    width: 310px;
    height: 50px;
    font-size: 1.1em;
    border-radius: 5px;
    border: none;
    padding-left: 10px;
}
    
.Formulario input::-webkit-outer-spin-button,
.Formulario input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Formulario input::placeholder {
    color: #013829;
    padding-left: 10px;
}

.Formulario label {
    font-size: 1.4em;
    display: flex;
    margin-bottom: 10px;
    color: #013829;
}


.Row {
    display: flex;
    align-items: center;
    width: 100%;
}

.Row div {
    width: 100%;
    padding-left: 5%;
}

.Botoes {
    display: flex;
    width: 100%;
    gap: 32%;
    padding-left: 10%;
}

.btn, .btn2 {
    width: 200px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #003A33;
    color: white;
    font-size: 1em;
    cursor: pointer;
}


.btn:hover, .btn2:hover {
    background-color: #E8FF8B;
    border-color: var(--forestgreen);
    color: #013829;
    transition: 0.5s;
}

@media screen and (max-width: 1300px) {
    .ServicoSection {
        transform: scale(0.9);
        width: 1200px;
    }

    .Div1 {
        width: 420px;
    }

    .Div2 {
        width: 100%;
    }

    .Formulario {
        width: 700px;
    }
    
    .Formulario input {
        width: 250px;
    }

    .Botoes {
        padding-left: 8%;
        gap: 25%;
    }
}


@media screen and (max-width: 1170px) {
    .ServicoSection {
        transform: scale(1);
        width: 90%;
    }

    .FormHeader {
        height: 25%;
        background-image: url("../../img/Banner2_ProGrid.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px;
        color: white;
    }

    .FormHeader p {
        color: white;
    }

    .Div1 {
        display: none;
    }

    .Div2 {
        width: 100%;
        border-radius: 10px;
    }

    .Formulario {
        width: 100%;
    }

    .Formulario input {
        width: 100%;
        font-size: 0.9em;
    }

    .Row {
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .Row div {
        padding: 0;
    }

    .Botoes {
        justify-content: space-around;
        padding: 0;
    }
    
    .btn, .btn2 {
        width: 220px;
        margin-top: 20px;
    }

    
}

@media (width <= 600px) {
    .ServicoSection {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 480px) {
    .btn, .btn2 {
        width: 220px;
        height: 50px;
        font-size: 0.9em;
        padding: 0;
    }
}

@media screen and (max-height: 800px) {
    .ServicoSection {
        transform: scale(0.8);
    }
}
