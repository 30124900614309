.navbar {
    position: absolute;
    height: 100vh;
    color: black;
    z-index: 1;
}

#hamburguer {
    font-size: 2.3em;
    top: 10px;
    left: 10px;
    position: absolute;
}

.menuOff {
    display: none;
}

.popUp {
    display: none;
}

.popUpShown {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 100%;
    color: white;
    background-color: #003730;   
}

.popUpShown header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 50px;
    padding: 15px;
}

.popUpShown i {
    font-size: 1.8em;

}

.row {
    display: flex;
    align-items: center;
    gap: 15px;;
    width: 90%;
    height: 60px;
    padding-left: 10px;
    margin: 0 auto;
    cursor: pointer;
    background-color: #11493D;
    border-radius: 20px;
}

.row p {
    padding-top: 8px;
    color: white;
}

.row i {
    color: white;
}

.row:hover {
    background-color: #003A33;
    transition: 0.6s;
}

footer {
    position: absolute;
    bottom: 0;
    background-color: #002b26;
    font-size: 0.5em;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (width <= 1200px) {
    .navbar {
        height: 100lh;
    }
}
