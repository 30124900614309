.home {
    background-color: #0E4841;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    flex-direction: column;
    width: 1750px;
    height: 800px;
    transform: scale(0.94);
    position: absolute;
    overflow-x: hidden;
}

.div1 {
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    font-size: 2.4em;
    background-color: #E8FF8B;
    color: #05362C;
    border-radius: 20px 20px 0px 0px;
    padding-top: 5%;
    padding-left: 3%;
}

.div2 {
    display: flex;
    background-color: #003A33;
    width: 100%;
    height: 40%;
    border-radius: 0 0 20px 20px;
}

.div2 div {
    display: flex;
    flex-direction: column;
    width: 31%;
    justify-content: center;
    color: white;
    padding: 80px;
    gap: 20px;
}

.div2 img {
    width: 10%;
}

.div3 {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    width: 600px;
    height: 100%;
    align-items: end;
    background-color: #e8ff8b00;
    border-radius: 20px;
}

.opaco1,
.opaco2,
.opaco3,
.opaco4 {
    background: radial-gradient(rgba(0, 58, 51, 0.486), #e8ff8b2f);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-radius: 20px 20px 0 0;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    width: 100%;
    height: 100%;
    align-self: flex-end;
}

.opaco1 {
    height: 50%;
}

.opaco2 {
    height: 60%;
}

.opaco3 {
    height: 70%;
}

.opaco4 {
    height: 80%;
    border-radius: 20px 20px 20px 0;
}

.banner {
    position: absolute;
    top: 90px;
    left: 120px;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 500px;
    background-color: #05735C;
    border-radius: 20px;
    color: white;
    padding: 5%;
    text-align: initial;
    gap: 30px;
    font-size: 0.95em;
    justify-content: end;
    background-image: url("../../img/banner5.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 5%;
}

.btn,
.btnMobile {
    width: 100%;
    border-radius: 20px;
    border: none;
    padding: 20px;
    background-color: #E8FF8B;
    color: #0E4841;
    font-size: 1.2vw;
}

.btnMobile {
    display: none;
}

.btn2 {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    border: none;
    padding: 20px;
    background-color: #05735C;
    color: white;
    font-size: 0.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:hover {
    cursor: pointer;
}

.popup {
    width: 225px;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    left: 20px;
    z-index: 3;
    border-radius: 20px;
    font-size: 0.6em;
    padding: 20px;
    gap: 20px;
    justify-content: center;
}

.popup div p {
    margin-bottom: 5px;
}

.servicoTag {
    background-color: #F4878C;
    padding: 10px;
    width: 65px;
    border-radius: 5px;
    color: white;
}

.simples {
    padding: 0 10px;
    box-shadow: inset 0 -0.4em 0 0 var(--gold);
}

@media (width <=1630px) {

    .content {
        width: 1500px;
        transform: scale(0.8);
    }

}

@media (width <=1228px) {

    .content {
        transform: scale(0.7);
    }

}

@media (width <=1080px) {
    .content {
        transform: scale(0.6);
    }
}

@media (width <=930px) {
    .content {
        transform: scale(0.5);
    }
}

@media (width <=790px) {

    .home {
        align-items: unset;
        justify-content: unset;
        width: unset;
        height: unset;
    }

    .content {
        transform: scale(1);
        width: 100vw;
        height: 100vh;
        position: relative;

    }

    .div1 {
        width: 100%;
        min-height: 750px;
        border-radius: 0;
    }

    .div3 {
        transform: scale(1);
        height: 800px;
        top: 300px;
        right: -80px;
    }

    .banner {
        width: 380px;
        height: 550px;
        left: 80px;
        font-size: 1.2em;
    }


    .opaco1,
    .opaco2,
    .opaco3,
    .opaco4 {
        border-radius: 20px 20px 0 0;
    }

    .opaco1 {
        border-radius: 20px 20px 0 20px;
    }

    .btn {
        font-size: 1.5em;
    }

    .btn2 {
        font-size: 1.5em;
    }

    .div2 {
        min-height: 1300px;
        flex-direction: column;
        border-radius: 0;
        align-items: center;
    }

    .div2 div {
        width: 100%;
        height: 30%;
        font-size: 1.5em;
    }

    #div2_1 {
        margin-top: 350px;
    }

    .div2 img {
        width: 20%;
    }

    .btnMobile {
        display: block;
        margin: 15% auto;
        width: 300px;
        font-size: 1.5em
    }

}



@media (width <=550px) {

    .div1 {
        font-size: 1.8em;
    }

    .banner {
        transform: scale(1.2);
        background-position: 0px -50px;
        background-color: #003A33;
    }

    .popup {
        top: -40px;
    }

    .div3 {
        transform: scale(0.7);
        top: 200px;
        right: -150px;
        height: 900px;
    }

    .div2 div {
        padding: 0;
        align-items: center;
        height: 500px;
        text-align: center;
        padding: 20px;
    }

    #div2_1 {
        margin-top: 200px;
    }
}

@media (width <=415px) {
    .div3 {
        transform: scale(0.6);
        right: -180px;
    }

    .div1 {
        font-size: 1.8em;
    }
}

@media (width <=405px) {
    .div1 {
        font-size: 7vw;
    }
}