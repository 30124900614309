.topo {
    position: absolute;
    top: 0;
}

.Projeto {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: white;
    overflow: hidden;

}


.ProjetoScrollLock {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: white;
}

.Conteudo {
    display: flex;
    width: 95%;
    margin: 4% auto;
    gap: 30px;
    
}

.projetoSection {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 830px;
    padding: 2%;
    padding-bottom: 10px;
    gap: 30px;
    background-color: #34495e2a;
    border-radius: 10px;
    box-shadow: 4px 4px 13px 0px #00000034;
}

.Header {
    width: 102%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}

.Header h1 {
    color: #013829;
}

.Header div {
    display: flex;
    width: 98%;
    justify-content: space-between;
    position: relative;
}

#headerLoading {
    position: absolute;
    top: 12px;
    left: 220px;
}

.CriarProjeto {
    position: absolute;
    top: 0;
    z-index: 2;
    background-color: rgba(128, 128, 128, 0.363);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

}

.CriarServico {
    position: absolute;
    top: 0;
    z-index: 1;
    background-color: rgba(128, 128, 128, 0.363);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Projetos {
    display: flex;
    flex-direction: column;
    width: 103%;
    height: 100%;
    gap: 25px;
    padding-bottom: 8px;
    overflow-y: scroll;

}

.ProjetoCard {
    width: 98%;
    height: 200px;
    border-radius: 20px;
    padding: 20px;
    background-color: #ffffff;
    text-align: start;
    color: #013829;
}

.ProjetoCard h1 {
    font-size: 1.7em;
}

.ProjetoCard p {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.Span1 {
    margin-left: 144px;
    box-shadow: 0px;
}

.Span2 {
    margin-left: 80px;
    box-shadow: 0px;
}

.Span3 {
    margin-left: 30px;
    box-shadow: 0px;
}

.projetoHeader {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.Icones {
    display: flex;
    gap: 5px;
    align-items: center;
}

.Icones i {
    font-size: 1.7em;
}

.Icones i:hover {
    cursor: pointer;
}


.error {
    color: crimson;
}


.btn {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    border: none;
    background-color: #003A33;
    color: white;
    font-size: 1em;
}


.btn:hover {
    background-color: #E8FF8B;
    border-color: var(--forestgreen);
    color: #013829;
    transition: 0.5s;
}

.btnProjeto {
    width: 100px;
    height: 30px;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    color: #013829;
}

.ServicosSection {
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 830px;
    padding: 2%;
    gap: 30px;
    background-color: #34495e2a;
    border-radius: 10px;
    box-shadow: 4px 4px 13px 0px #00000034;
}

.Header2 {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: #013829;
}

.header2Div1 div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.Header2 div p {
    padding-top: 3px;
    font-size: 1em;
}

.header2Div1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.Servicos {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    padding-left: 2%;
    overflow-y: scroll;
}


.ServicoCard {
    width: 98%;
    height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: start;
    border-radius: 10px 20px 20px 10px;
    padding: 20px 10px 10px 30px;
    position: relative;
    color: #013829;
}

.ServicoCard h1 {
    font-size: 1.7em;
    margin-bottom: 25px;
}

.ServicoCard p {
    font-size: 1.2em;
    margin-bottom: 5px;
}

.ServicoCard i {
    position: absolute;
    right: 50px;
    top: 38%;
    font-size: 2em;
}

.ServicoCard::before {
    content: ".";
    width: 1.3%;
    height: 100%;
    border-radius: 20px 0 0 20px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #003A33;
}

.searchbar {
    padding: 10px;
    border-radius: 10px;
    width: 98%;
    display: flex;
    border: none;
}


@media screen and (max-width: 1200px) {

    .Projeto {
        overflow: visible;
        overflow-x: hidden;
    }

    .Conteudo {
        width: 100%;
        padding: 2%;
        margin-top: 10%;
        flex-direction: column;
    }

    .Projeto h1 {
        font-size: 1.4em;
    }

    .Projeto p {
        font-size: 1.0em;
    }

    .projetoSection {
        width: 100%;
        border-radius: 10px;
    }

    .Projetos::-webkit-scrollbar {
        display: none;
    }

    .btn {
        width: 120px;
        font-size: 0.8em;
        margin-right: 10px;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
    }

    .ServicosSection {
        width: 100%;
        border-radius: 10px;
    }


}


@media (width <= 470px) {

    .Conteudo {
        margin-top: 20%;
    }

}

@media (height <= 900px) {
    .Conteudo {
        transform: scale(0.95);
    }
}