.Loading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100px;
}

.Loading div {
    display: flex;
    font-size: 0.7em;
}

.Loading p {
    font-size: 0.7em;
}

.Loading i {
    font-size: 1.5em;
    padding-top: 6px;
    padding-left: 5px;
    cursor: pointer;
}