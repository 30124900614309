* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Articulate";
  text-decoration: none;
  font-weight: lighter;
  
}

body {
  margin: 0;
  padding: 0;
  background-color: #EAEEF4;
  -webkit-text-size-adjust: 100%
}




